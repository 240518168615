<template>
  <div class="content-box vision-box">
    <!-- <v-Header :isShowTitle="true" :showback="true" tittle='老花眼视力检查' @back="$router.go(-1)">
    </v-Header> -->
    <div v-wechat-title="'老花眼视力检查'"></div>
    <div class='page-content'>
      <div class='vision-box-content'>
        <div class='flex flex-align-items vision-title-block' style="justify-content:space-between">
          <div style="position: relative">
            <audio id="myAudio" hidden="true" controls ref="myAudio" @ended="overAudio">
              <source :src="playSrc" type="audio/mpeg" />
            </audio>
            <!-- <img v-if="!isPlay" src="../../assets/imgs/c.png" alt="" style="width:24px" /> -->
            <div v-if="isPlay" class="mountain"></div>
          </div>
          <div>
            <img src="../../assets/imgs/eye.png" alt="">{{
                $store.state.eyeText
            }}
          </div>
          <div>
            <!-- <span class='right-word' @click="$router.push({ name: 'index' })">低视力检查</span> -->
          </div>
        </div>
        <div class='vision-content'>
          <!-- 第{{ ins + 1 }}行 -->
          <ul class='list'>
            <div id="echart" class="divc">
              <li v-for='(item, index) in $Elist' :key=index v-show='ins == index' v-touch:left="onSwipeLeft"
                v-touch:right="onSwipeRight" v-touch:down="onSwipeDown" v-touch:up="onSwipeUp"
                class='flex flex-align-items flex-justify-content'>
                <div :class="currentItem.class == 'type1' ?
              'type1' : currentItem.class == 'type2' ?
                'type2' : currentItem.class == 'type3' ? 'type3' : 'type4'">
                  <img src="../../assets/imgs/E.png" :style="{ width: (width * item.scale) + 'px' }" alt="">
                </div>

              </li>
            </div>
            <div class='clock-box flex flex-align-items'>
              <i v-show='n == 3 || n == 2 || n == 1' class='clock-circle'></i>
              <i v-show='n == 3 || n == 2' class='clock-circle'></i>
              <i v-show='n == 3' class='clock-circle'></i>
            </div>

          </ul>
          <!--  -->
        </div>
        <!-- <div class='audio-box align-left'>
          <img src="../../assets/imgs/audio.png" alt="">语音识别中
        </div> -->
        <div class='flex bottom-box' style="width:calc(100% - 60px);padding-bottom: 30px;">
          <div class='flex-1'>
            <img src="../../assets/imgs/up-icon.png" alt="" @click='onSwipeUp'>
          </div>
          <div class='flex-1'>
            <img src="../../assets/imgs/down-icon.png" alt="" @click='onSwipeDown'>
          </div>
          <div class='flex-1'>
            <img src="../../assets/imgs/cancel.png" alt="" @click='cancel'>
          </div>
          <div class='flex-1'>
            <img src="../../assets/imgs/left-icon.png" alt="" @click='onSwipeLeft'>
          </div>
          <div class='flex-1'>
            <img src="../../assets/imgs/right-icon.png" alt="" @click='onSwipeRight'>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import vHeader from '../../components/header.vue'
import commonVision from '../../components/commonVision.vue'
import { Dialog } from 'vant';
export default {
  data() {
    return {
      tittle: '',
      ins: 0,
      width: 0.726 / (this.$store.state.sys_param.Dpi * 1 / this.$store.state.sys_param.PPI * 2.45),
      innerIns: 0,
      n: 0,
      infoMessage: '',
      currentItem: {},
      timer: null,
      isPlay: true,
      currentCorrectCount_left: 0,
      currentCorrectCount_right: 0,
      currentErrorCount_left: 0,
      currentErrorCount_right: 0,
      tmpArr: [0, 1, 2, 3],
    }
  },
  components: {
    vHeader, commonVision
  },
  watch: {
    ins: {
      handler(o, n) {
        this.currentCorrectCount_left = 0;
        this.currentCorrectCount_right = 0;
        this.currentErrorCount_left = 0;
        this.currentErrorCount_right = 0;
      }
    },
    result: {
      deep: true,
      handler(o) {
        if (o.indexOf('左') != -1) {
          this.onSwipeLeft();
        } else if (o.indexOf('右') != -1) {
          this.onSwipeRight()
        } else if (o.indexOf('上') != -1) {
          this.onSwipeUp()
        } else if (o.indexOf('下') != -1) {
          this.onSwipeDown()
        }
        // this.$refs['voiceRef'].start()
      }
    },
    infoMessage: {
      immediate: true,
      deep: true,
      handler(o) {
        if (o) {
          let str = this.$store.state.eyeText == '左眼' ? '右眼' : '完成';
          if (this.$store.state.eyeText == '左眼') {
            if (this.ins == 0) {
              this.$store.commit('set_left_eye', `${0.05} (${3.7})`)
            } else {
              this.$store.commit('set_left_eye', `${(this.$Elist[this.ins-1].decimalRecord).toFixed(2)} (${(this.$Elist[this.ins-1].fivePointsRecord).toFixed(1)})`)
            }
          } else {
            if (this.ins == 0) {
              this.$store.commit('set_right_eye', `${0.05} (${3.7})`)
            } else {
              this.$store.commit('set_right_eye', `${(this.$Elist[this.ins-1].decimalRecord).toFixed(2)} (${(this.$Elist[this.ins-1].fivePointsRecord).toFixed(1)})`)
            }

          }
          let c = '';
          if (this.$store.state.rightEye) {
            c = `右眼:${this.$store.state.rightEye}`
          }
          let text = `左眼:${this.$store.state.leftEye}\r\n\r\n${c}`;
          Dialog.confirm({
            title: '您的检查结果',
            message: text,
            'cancelButtonText': '重新检查',
            'confirmButtonText': '检查' + str,
          }).then((action, done) => {
            if (action == 'confirm') {
              if (str == "右眼") {
                this.$store.commit("set_currentErrorCount_left", this.currentErrorCount_left);
                this.$store.commit("set_currentCorrectCount_left", this.currentCorrectCount_left);
              }
              this.$store.commit('set_eye_text', str)
              if (this.$store.state.eyeText == '完成') {
                this.$httpProxy.post('/api/visual/InsertEyes', {
                  VisualId: this.$route.query.visualId,
                  Type: 3,
                  LeftMax: this.$store.state.leftEye.split('(')[0].replace(' ', ''),
                  LeftMin: this.$store.state.leftEye.split('(')[1].replace(')', ''),
                  RightMax: this.$store.state.rightEye.split('(')[0].replace(' ', ''),
                  RightMin: this.$store.state.rightEye.split('(')[1].replace(')', ''),
                  LeftCorrectCount: this.$store.state.currentCorrectCount_left,
                  LeftErrorCount: this.$store.state.currentErrorCount_left,
                  RightErrorCount: this.currentErrorCount_right,
                  RightCorrectCount: this.currentCorrectCount_right,
                }).then((res) => {
                  if (res.data.Success) {
                    // this.$router.go(0);
                    this.$router.push({ name: "index" });
                  } else {
                    this.$message.warning('出错了')
                  }
                })
              } else {
                window.location.reload();
              }
              // this.$router.go(0);
            } else {
              done();
            }
          }).catch(() => {
            window.location.reload();
            // this.$router.go(0);
          });
        }
      }
    }

  },
  created() {
    let _this = this;
    _this.n = sessionStorage.getItem('clock') ? sessionStorage.getItem('clock') : this.$time;
    if (JSON.parse(sessionStorage.getItem('isInterval'))) {
      this.timer = setInterval(() => {
        if (_this.n > 0) {
          _this.n--;
        } else {
          _this.cancel();
          _this.isNext();
        }
      }, 1000)
    }
    this.currentItem = this.$rowList[Math.round(Math.random() * 3)];
    this.$httpProxy.get('/api/visual/get', { Id: this.$route.query.userId }).then((res) => {
      if (res.data.Success) {
        let initIni = res.data.ResData.Ys
        if (Object.keys(initIni).length > 0) {
          let i = 0;
          if (this.$store.state.eyeText == "左眼") {
            i = initIni[Object.keys(initIni)[0]][0].LeftMax;
          } else {
            i = initIni[Object.keys(initIni)[0]][0].RightMax;
          }
          let c = this.$Elist.findIndex(e => e.decimalRecord == i) || 0;
          if (c - 1 > 0) {
            this.ins = c - 1
          } else {
            this.ins = 0;
          }
        }
      } else {
        this.$message.warning('出错了')
      }
    })
  },
  mounted() {
    document.addEventListener("WeixinJSBridgeReady", function () {
      console.log("WeixinJSBridgeReady");
      document.getElementById('myAudio').play(); //视频自动播放
    }, false);
    if (window.name == 1) {
      window.name = 2;
      window.location.reload();
    }
    var video = document.querySelector('audio');
    document.addEventListener('visibilitychange', () => {
      // 当页面隐藏时候
      if (document.visibilityState === 'visible') {
        // 视频暂停
        // video.play();
        this.isPlay = false
      } else {
        // 当页面显示时视频开始播放
        video.pause();
      }
    });
  },
  computed: {
    playSrc() {
      if (this.$store.state.eyeText == "左眼") {
         return '/assets/Amsler/audios/1.1.mp3'
      } else {
         return '/assets/Amsler/audios/1.2.mp3'
      }
    },
  },
  methods: {
    overAudio(e) {
      this.isPlay = false
      // this.$refs.myAudio.load();
      // this.$refs.myAudio.src=this.src
      // this.$refs.myAudio.play();
    },
    onSwipeUp() {
      if (this.currentItem.correctAnswer == 'top') {
        this.animeCheck(0, -1000)
        // this.$message.success('你答对了');
        if (this.$store.state.eyeText == "左眼") {
          this.currentCorrectCount_left += 1
        } else {
          this.currentCorrectCount_right += 1
        }
      } else {
        if (this.$store.state.eyeText == "左眼") {
          this.currentErrorCount_left += 1
        } else {
          this.currentErrorCount_right += 1
        }
        if (this.ins > 0) {
          this.infoMessage = '您的小数点位视力为' + this.$Elist[this.ins - 1].decimalRecord +
            '您的5分记录视力为' + this.$Elist[this.ins - 1].fivePointsRecord;
          return
        }
        clearInterval(this.timer);
        this.infoMessage = '0.05'
      }
    },
    onSwipeDown() {
      if (this.currentItem.correctAnswer == 'bottom') {
        this.animeCheck(0, 1000)
        if (this.$store.state.eyeText == "左眼") {
          this.currentCorrectCount_left += 1
        } else {
          this.currentCorrectCount_right += 1
        }
      } else {
        if (this.$store.state.eyeText == "左眼") {
          this.currentErrorCount_left += 1
        } else {
          this.currentErrorCount_right += 1
        }
        if (this.ins > 0) {
          this.infoMessage = '您的小数点位视力为' + this.$Elist[this.ins - 1].decimalRecord +
            '您的5分记录视力为' + this.$Elist[this.ins - 1].fivePointsRecord;
          return
        }
        this.infoMessage = '0.05'
        clearInterval(this.timer);
      }
    },
    onSwipeLeft() {
      if (this.currentItem.correctAnswer == 'left') {
        this.animeCheck(-1000, 0);
        if (this.$store.state.eyeText == "左眼") {
          this.currentCorrectCount_left += 1
        } else {
          this.currentCorrectCount_right += 1
        }
      } else {
        if (this.$store.state.eyeText == "左眼") {
          this.currentErrorCount_left += 1
        } else {
          this.currentErrorCount_right += 1
        }
        if (this.ins > 0) {
          this.infoMessage = '您的小数点位视力为' + this.$Elist[this.ins - 1].decimalRecord +
            '您的5分记录视力为' + this.$Elist[this.ins - 1].fivePointsRecord;
          return
        }
        this.infoMessage = '0.05'
        clearInterval(this.timer);
      }
    },
    onSwipeRight() {
      if (this.currentItem.correctAnswer == 'right') {
        this.animeCheck(1000, 0)
        if (this.$store.state.eyeText == "左眼") {
          this.currentCorrectCount_left += 1
        } else {
          this.currentCorrectCount_right += 1
        }
      } else {
        if (this.$store.state.eyeText == "左眼") {
          this.currentErrorCount_left += 1
        } else {
          this.currentErrorCount_right += 1
        }
        if (this.ins > 0) {
          this.infoMessage = '您的小数点位视力为' + this.$Elist[this.ins - 1].decimalRecord +
            '您的5分记录视力为' + this.$Elist[this.ins - 1].fivePointsRecord;
          return
        }
        clearInterval(this.timer);
        this.infoMessage = '0.05'
      }
    },
    cancel() {
      // this.$message.error('你放弃回答');
      if (this.ins > 0) {
        this.infoMessage = '您的小数点位视力为' + this.$Elist[this.ins - 1].decimalRecord +
          '您的5分记录视力为' + this.$Elist[this.ins - 1].fivePointsRecord;
        clearInterval(this.timer);
        return
      } else {
        this.infoMessage = '0.05'
        clearInterval(this.timer);
      }
    },
    animeCheck(left = 0, rigth = 0, next) {
      let div = document.getElementById('echart');
      div.style = `-webkit-transform: translate(${left}px,${rigth}px); transition: 1s ease 0s; `
      setTimeout(() => {
        div.style = `-webkit-transform: translate:none; transition: 0s ease 0s;`
        this.isNext();
      }, 1000)
    },
    isNext() {
      if (!this.infoMessage) {
        this.n = sessionStorage.getItem('clock') ? sessionStorage.getItem('clock') : this.$time;
        if (this.ins < this.$Elist.length - 1) {
          if (this.innerIns < 3) {
            this.innerIns++;
          } else {
            this.innerIns = 0;
            this.ins++;
          }
          if (this.tmpArr.length > 0) {
            let c = this.tmpArr[Math.floor(Math.random() * this.tmpArr.length)]
            this.tmpArr.splice(this.tmpArr.indexOf(c), 1)
            this.currentItem = this.$rowList[c];
          } else {
            this.tmpArr = [0, 1, 2, 3]
            let c = this.tmpArr[Math.floor(Math.random() * this.tmpArr.length)]
            this.tmpArr.splice(this.tmpArr.indexOf(c), 1)
            this.currentItem = this.$rowList[c];
          }
        } else {
          if (this.innerIns < 3) {
            this.innerIns++;
            if (this.tmpArr.length > 0) {
              let c = this.tmpArr[Math.floor(Math.random() * this.tmpArr.length)]
              this.tmpArr.splice(this.tmpArr.indexOf(c), 1)
              this.currentItem = this.$rowList[c];
            } else {
              this.tmpArr = [0, 1, 2, 3]
              let c = this.tmpArr[Math.floor(Math.random() * this.tmpArr.length)]
              this.tmpArr.splice(this.tmpArr.indexOf(c), 1)
              this.currentItem = this.$rowList[c];
            }
          } else {
            this.$message.warning('暂无')
          }
        }
      } else {

        return
      }

    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    document.getElementById('myAudio').pause();
  },
  destroyed() {
    clearInterval(this.timer);
    document.getElementById('myAudio').pause();
  },
};
</script>


 
<style lang="less">
@import "../../assets/less/vision.less";

.divc {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>